.border-radius-top {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;  
}

.border-radius-bottom {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;  
}
.border-radius {
    border-radius: 20px;
}
/* .team-item .overflow-hidden {
    height: 150px;  
    position: relative;
}

.team-item .overflow-hidden img {
    object-fit: cover; 
    height: 100%;  
    width: 100%;  
} */
.background-image {
    width: 100%;
    height: 100%;
    object-fit: fill; /* Ensures the image covers the screen without distortion */
    position: absolute; /* Keeps the image fixed in the background */
    top: 0;
    left: 0;
    z-index: -1; /* Sends the image behind other content */
  }
.bird {
    background-image: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/174479/bird-cells-new.svg');
    filter: invert(45%) sepia(75%) saturate(700%) hue-rotate(75deg) brightness(95%) contrast(110%);

    background-size: auto 100%;
    width: 88px;
    height: 125px;
    will-change: background-position;

    animation-name: fly-cycle;
    animation-timing-function: steps(10);
    animation-iteration-count: infinite;
}

.bird-one {
    animation-duration: 1s;
    animation-delay: -0.5s;
}

.bird-two {
    animation-duration: 0.9s; /* Fixed missing unit */
    animation-delay: -0.75s; /* Fixed syntax */
}

.bird-three {
    animation-duration: 1.25s;
    animation-delay: -0.25s;
}

.bird-four {
    animation-duration: 1.1s;
    animation-delay: -0.5s;
}

.bird-container {
    position: absolute;
    top: 10%;
    left: -3%;
    transform: scale(0) translateX(-10vw);
    will-change: transform;

    animation-name: fly-right-one;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

.bird-container-one {
    animation-duration: 15s;
    animation-delay: 0;
}

.bird-container-two {
    animation-duration: 16s;
    animation-delay: 1s;
}

.bird-container-three {
    animation-duration: 14.6s;
    animation-delay: 9.5s;
}

.bird-container-four {
    animation-duration: 16s;
    animation-delay: 10.25s;
}

/* Updated keyframes for correct background positioning */
@keyframes fly-cycle {
    100% {
        background-position: -880px 0; /* Adjust to fit your sprite width */
    }
}

/* Fly movement */
@keyframes fly-right-one {
    0% {
        transform: scale(0.3) translateX(-10vw);
    }

    10% {
        transform: translateY(2vh) translateX(10vw) scale(0.4);
    }

    20% {
        transform: translateY(0vh) translateX(30vw) scale(0.5);
    }

    30% {
        transform: translateY(4vh) translateX(50vw) scale(0.6);
    }

    40% {
        transform: translateY(2vh) translateX(70vw) scale(0.6);
    }

    50% {
        transform: translateY(0vh) translateX(90vw) scale(0.6);
    }

    60% {
        transform: translateY(0vh) translateX(110vw) scale(0.6);
    }

    100% {
        transform: translateY(0vh) translateX(110vw) scale(0.6);
    }
}
